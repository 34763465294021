import React from 'react'

// layouts
import Layout from 'src/layouts/BaseLayout'
import { Wrapper } from './style'

// Sections
import Header from './sections/header/_index'
import NaoAdaptou from './sections/formNaoAdaptou/_index'
import HelpCenter from '../helpCenter/_index'

import pageContext from './pageContext.json'

const Experiencia = () => {
  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Header />
        <NaoAdaptou />
        <HelpCenter />
      </Layout>
    </Wrapper>
  )
}

export default Experiencia
